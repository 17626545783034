<script setup lang="ts">
import { useCartStore } from '~/stores/cartStore';
import { useTokensStore } from '~/stores/tokensStore';
import EVENTS, { SECTIONS } from '~/constants/events';

const cartStore = useCartStore();

const tokensStore = useTokensStore();

const { meta } = useRoute();

const { $trackEvent } = useNuxtApp();

const tokensCount = computed(() => tokensStore.validTokens.length);

const modal = useModal();

const cartItems = cartStore.cart.products;

const cartTitle = computed((): string => {
  if (cartStore.isEmptyCart) {
    return 'Your Cart is empty';
  }

  return 'Your Cart';
});

const totalCost = computed(() => {
  return cartStore.cart.products.length;
});

const closeModalClicked = (section: string) => {
  if (modal.isOpen.value) {
    modal.close();
    $trackEvent(EVENTS.CART_CLOSED, {
      page: meta.eventPageName,
      section: section,
    });
  }
};

const fullScreenModal = computed(() => {
  return window?.innerWidth < 640;
});

const goToCheckout = async () => {
  if (modal.isOpen.value) {
    modal.close();
  }

  $trackEvent(EVENTS.CHECKOUT_CLICKED, {
    cart_product_count: totalCost.value,
    page: meta.eventPageName,
    section: SECTIONS.NAVIGATIONAL_CART,
  });
  await navigateTo({ name: 'checkout' });
};
</script>

<template>
  <UModal
    :fullscreen="fullScreenModal"
    :ui="{
      container: 'sm:absolute right-0 top-0 sm:w-[450px] bg-beige-100 p-0',
      base: 'sm:h-dvh',
      margin: 'my-0 sm:my-0',
      padding: 'p-0',
      shadow: 'shadow-none',
      fullscreen: 'h-dvh',
    }"
  >
    <div
      class="bg-beige-100 flex shrink-0 items-center justify-between p-4 sm:px-10 sm:py-5"
    >
      <h2 class="text-2xl font-bold">
        {{ cartTitle }}
      </h2>
      <UIcon
        name="i-heroicons-x-mark"
        class="h-6 w-6 cursor-pointer"
        @click.stop="closeModalClicked(SECTIONS.CLOSE_CART_CLICKED)"
      />
    </div>

    <div class="bg-beige-100 grow overflow-y-auto px-4 sm:px-10">
      <CartCard />
    </div>

    <footer class="bg-beige-100 shrink-0 p-4 sm:px-10 sm:py-5">
      <CartCostLine :total-cost="totalCost" class="mb-4" />
      <div class="flex flex-col items-center">
        <div class="w-full pb-4">
          <UButton
            block
            color="primary"
            variant="solid"
            size="xs"
            class="py-2.5"
            :ui="{
              base: 'focus:outline-none focus-visible:outline-0 disabled:cursor-not-allowed disabled:border-black disabled:bg-yellow disabled:opacity-30 flex-shrink-0',
            }"
            :disabled="
              cartItems.length <= 0 ||
              cartItems.length > tokensCount ||
              tokensCount === 0
            "
            @click="goToCheckout"
          >
            Checkout
          </UButton>
        </div>
        <UButton
          block
          color="secondary"
          variant="solid"
          size="xs"
          class="py-2.5"
          @click="closeModalClicked(SECTIONS.CONTINUE_SHOPPING)"
        >
          Continue Shopping
        </UButton>
      </div>
    </footer>
  </UModal>
</template>
