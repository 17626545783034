export function useUserSupport() {
  const user = useCurrentUser();

  const supportEmailLink = computed(() => {
    const params = {
      subject: `Taste Network Support - samplerId: ${user.value?.uid}`,
    };

    const queryString = new URLSearchParams(params).toString();

    return `mailto:support@tastenetwork.io?${queryString}`;
  });

  return {
    supportEmailLink,
  };
}
