import type { HeaderLink } from '~/types';

export const HEADERLINKS: HeaderLink[] = [
  {
    label: 'Home',
    to: '/members',
  },
  {
    label: 'Shop All',
    to: { name: 'products' },
  },

  {
    label: 'Promotions',
    to: { name: 'promotions' },
  },

  {
    label: 'How it Works',
    to: { name: 'how-it-works' },
  },
];
